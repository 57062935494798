
@media screen and (max-width: 700px) {

  .main-body-content.dashboard {
    padding-left: var(--mobile-outside-padding);
    padding-right: var(--mobile-outside-padding);
  }

}


@media screen and (min-width: 700px) and (max-width: 835px) {

  .main-body-content.dashboard {
    padding-top: 15px;
  }

  .dashboad-help-card {
    margin-bottom: 130px !important;
  }

}




/* ------------------------------------------------------------ */
/* Week List */
/* ------------------------------------------------------------ */

.week-list-container {
  margin-top: 15px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: var(--gray-light);

  border: initial;
  width: 100%;
}

.week-list-container.collapse {
  opacity: .75;
}

.week-list-container:first-of-type {
  margin-top: initial;
}

/* .week-list-container.is-this-week {

} */

button.week-list-title-button {
  flex-grow: 1;
  text-align: left;
  cursor: pointer;
}

button.week-list-title-button:disabled {
  cursor: initial;
}

.week-list-header-container h1 {

  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.week-list-header-container h2 {
  flex-grow: 1;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

.week-list-header-container p.helper-text {
  max-width: 55px;
  margin-top: 4px;
  margin-left: 5px;
  font-size: .7rem;
  line-height: .8rem;
  text-align: center;
  color: var(--gray-tertiary);
}

.week-list-body {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
}

.week-list-body-inner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.week-list-body-inner.disabled {
  pointer-events: none;
  opacity: .65;
}

.week-list-container.collapse {

}

.week-list-container.collapse .week-list-body {
  opacity: 0;
  height: 0px;
  margin-top: 0px;
  display: none;
}


/* Upgrade CTA */
.week-list-upgrade-cta-container {
  width: 100%;
  margin: 30px 0;
}

.week-list-upgrade-cta-container p {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  color: var(--gray-secondary);
  text-align: center;
}

.week-list-upgrade-cta-header {
  max-width: 400px;
}

.week-list-upgrade-cta-header p {
  margin: 0 15px;
  font-size: 1.25rem;
  color: var(--active);
}

.week-list-upgrade-cta-header div {
  flex: 1;
  flex-shrink: 1;
  display: block;
  width: 30px;
  height: 2px;
  border-radius: 3px;
  background-color: var(--active);
}

.load-more-lessons-container {
  margin-top: 30px;
}

.load-more-lessons-container button {
  font-size: .9rem;
  color: var(--gray-tertiary);
  text-decoration: underline;
}



/* Score donut with text */
.week-score-container {
  min-height: 55px;
  min-width: 60px;
}

.score-donut-wrapper.with-text,
.score-donut-wrapper.with-text .donut-hole,
.score-donut-wrapper.with-text .score-donut {
  width: 55px;
  height: 55px;
  border-radius: 40px;
}


.score-donut-wrapper.with-text {
  display: block;
}

.score-donut-wrapper.with-text .pie {
  --b: 7px;
  --w: 55px;
}

.score-donut-wrapper.with-text .donut-hole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 7px;
}

.score-donut-wrapper.with-text .donut-hole p {
  font-size: .9rem;
  color: var(--gray-tertiary);
}

/* .score-donut-wrapper.with-text .score-donut {
} */




/* Bonus lessons */
.bonus-lessons-container {
  width: 100%;
  margin-top: 45px;
  margin-bottom: 15px;
  padding-top: 30px;
  text-align: center;
  border-top: 1px solid var(--white-tertiary);
}

.bonus-lessons-container p,
.bonus-lessons-container a {
  color: var(--gray-tertiary);
  line-height: 1.2rem;
}

.bonus-lesson-locked {
  justify-content: center;
  width: 65px;
  height: 65px;
  margin: 0 10px;
  border-radius: 10px;
  border: 1px solid var(--white-tertiary);
  background: rgb(230,242,244);
  background: linear-gradient(147deg, rgba(230,242,244,1) 0%, rgba(202,222,245,1) 100%);
}

.bonus-lessons-list-container {
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 15px;
}

.add-more-bonus-lessons-button {
  margin-top: 30px;
  font-size: .9rem;
  color: var(--gray-tertiary);
  text-align: center;
  text-decoration: underline;
}

.add-more-bonus-lessons-button:hover {
  color: var(--gray-secondary);
}


@media screen and (max-width: 450px) {

  .week-list-container {
    padding: 15px;
  }

  .week-list-header-container h1 {
    font-size: 2rem;
  }

  .bonus-lessons-container {
    margin-top: 45px;
    padding-top: initial;
    border-top: initial
  }

  .bonus-lessons-container p,
  .bonus-lessons-container a {
    font-size: .9rem;
  }

  .bonus-lesson-locked {
    margin: 0 5px;
  }

}




/* ------------------------------------------------------------ */
/* Pathway overrides */
/* ------------------------------------------------------------ */

.main-body-content.dashboard .pathway-wrapper {
  background-color: var(--white-primary);
}

.main-body-content.dashboard .pathway-wrapper.selected {
  background-color: #FDF1FA;
}

















/* ------------------------------------------------------------ */
/* Lesson cards container */
/* ------------------------------------------------------------ */


.lesson-cards-container {
  margin-bottom: 90px;
}

.lesson-cards-container .action-button-wrapper {
  width: 100%;
  margin-top: 30px;
}

.lesson-cards-container .action-button-wrapper .action-button {
  font-size: 1rem;
  border-radius: 5px;
  background: var(--gray-primary);
}


@media screen and (min-width: 800px) {

  .lesson-cards-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }

}


/* ------------------------------------------------------------ */
/* Help card */
/* ------------------------------------------------------------ */

.dashboad-help-card {
  /* margin: 0 -60px; */
  /* padding: 0px 60px 45px 60px; */
  margin-bottom: 30px;
  font-size: .9rem;
  text-align: center;
  /* background-color: var(--gray-extra-light); */
}



/* ------------------------------------------------------------ */
/* App Links Card */
/* ------------------------------------------------------------ */

.dashboad-app-links-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin: 60px -60px 0 -60px; */
  margin-top: 90px;
  padding: 45px 0;
  border-top: 1px solid var(--white-tertiary);
  /* background-color: var(--gray-extra-light); */
}

.dashboad-app-links-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  /* flex-wrap: wrap; */
  gap: 15px;
}

.dashboad-app-links-card p {
  font-size: 1.125rem;
  margin-bottom: 10px;
  text-align: center;
}

.dashboad-app-links-card a {
  text-align: center;
}

.dashboad-app-links-card a img {
  max-height: 45px;
}





/* ------------------------------------------------------------ */
/* Upgrade CTA */
/* ------------------------------------------------------------ */

.dashboard-upgrade-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding: 15px;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  text-align: center;
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
  background-color: var(--white-primary);
}

.dashboard-upgrade-card h5 {
  font-size: 1.125rem;
  color: var(--active);
  font-weight: 500;
}

.dashboard-upgrade-card p {
  font-size: 1.125rem;
  color: var(--gray-primary);
}



/* ------------------------------------------------------------ */
/* Live Chat Card */
/* ------------------------------------------------------------ */

.lesson-card.live-chat-card {
  height: auto;
  padding: 0px;
  box-shadow: 0 2px 8px -2px rgb(0 0 0 / 50%);
}

.lesson-card.live-chat-card {
  background-image: url('../images/live-chat-backbround.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.lesson-card.live-chat-card p {
  color: var(--white-primary);
  font-size: 1.25rem;
  font-weight: 500;
  padding: 15px 30px;
}

.lesson-card.live-chat-card a {
  display: block;
  color: var(--active);
  text-decoration: underline;
  padding: 10px 30px;
  font-weight: 500;
  background-color: var(--white-primary);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}







/* ------------------------------------------------------------ */
/* Score */
/* ------------------------------------------------------------ */

.score-wrapper {
  padding: 15px 30px;
  border-top: 1px solid var(--white-tertiary);
}

.score-wrapper .score-line {
  display: flex;
  align-items: center;
}

.score-wrapper .score-title,
.score-wrapper .score-detail-title {
  width: 120px;
}

.score-wrapper .score-title {
  font-size: 1.125rem;
  font-weight: 500;
}

.score-wrapper .score-detail.score {
  font-size: 1.25rem;
  font-weight: 600;
}

.score-wrapper .score-spacer {
  flex-grow: 1;
}

.score-wrapper .score-toggle {
  width: 1.8em;
  height: 1em;
  border: none;
  cursor: pointer;

  background-color: transparent;
}

.score-wrapper .score-toggle::before {
	position: relative;
  top: 4px;
  display: inline-block;
  width: 0.65em;
  height: 0.65em;
  content: '';
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
	transform: rotate(-45deg);
	vertical-align: top;
}

.score-wrapper .score-toggle.down:before {
	top: 0px;
  transform: rotate(135deg);
}

.score-details-wrapper {
  margin-top: 15px;
}

.score-wrapper .score-detail-title {
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.score-wrapper .score-detail {
  font-size: .9rem;
  font-weight: 500;
}

.score-wrapper .score-detail.small {
  font-size: .8rem;
  font-weight: 400;
  color: var(--gray-tertiary);
  font-style: italic;
  margin-top: 5px;
}

.score-wrapper .score-bar {
  width: 50px;
  height: 10px;
  margin-right: 15px;
  border-radius: 5px;
  background-color: var(--active-half);
}

.score-wrapper .score-bar-spacer {
  width: 50px;
  margin-right: 15px;
}

.score-wrapper .score-bar-fill {
  height: 100%;
  border-radius: inherit;
  background-color: var(--active);
}

.score-wrapper a.score-article-link {
  display: inline-block;
  font-size: .9rem;
  font-style: italic;
  text-decoration: underline;
  color: var(--gray-tertiary);
  padding-top: 15px;
}



/* ------------------------------------------------------------ */
/* Info Card */
/* ------------------------------------------------------------ */

.lesson-card.info-card {
  /* max-width: 475px; */
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
  border: 1px solid var(--white-tertiary);
  border-radius: 10px;
  box-shadow: none;
}

.info-card .header-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  /* margin-bottom: 15px; */
  margin-bottom: 5px;
}

.info-card .header-block .icon {
  margin-top: 7px;
}

.info-card .header-block h3 {
  padding: 0 15px;
  color: var(--active);
}

.info-card .header-block img {
  max-width: 50px;
  margin-bottom: 15px;
}

.info-card .action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.info-card p {
  text-align: center;
}

.info-card a {
  color: var(--gray-secondary);
  text-decoration: underline;
}

.info-card .action-button {
  /* margin: 15px -30px -30px -30px; */
  margin: 30px -16px -16px -16px;
  padding: 15px;
  text-decoration: underline;
  color: var(--white-primary);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: var(--active);
}

.info-card .icon {
  height: 15px;
}

.info-card .action-button .icon {
  margin-left: 15px;
}



@media screen and (min-width: 700px) {


}


/* ------------------------------------------------------------ */
/* Phone prompt */
/* ------------------------------------------------------------ */

.window-modal-body.phone-prompt {
  padding-bottom: 30px;
}

.window-modal-body.phone-prompt p {
  margin-bottom: initial;
}

.window-modal-body.phone-prompt .action-button-wrapper {
  margin-top: 0px;
}


/* ------------------------------------------------------------ */
/* Discount Card */
/* ------------------------------------------------------------ */

.lesson-card.special-discount-card {
  padding: 0px;
  background-image: url('../images/discount-backbround.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.lesson-card.special-discount-card h5 {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--white-primary);
  padding: 10px 30px;
}

.lesson-card.special-discount-card div {
  color: var(--gray-primary);
  padding: 10px 30px;
  background-color: var(--white-primary);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.lesson-card.special-discount-card div p {
  margin-bottom: 10px;
  font-size: .9rem;
}

.lesson-card.special-discount-card div p.bigger {
  font-size: 1.125rem;
  font-weight: 500;
}



/* ------------------------------------------------------------ */
/* Community Cta */
/* ------------------------------------------------------------ */

a.community-cta {
  display: block;
  margin-bottom: 30px;
  padding: 30px 0;
  color: var(--gray-primary);
}

.community-cta h3 {
  font-size: 2rem;
  font-weight: 700;
}

.community-cta p {
  font-size: 1.125rem;
  text-decoration: underline;
}

.community-cta.desktop-only {
  display: none;
}




/* ------------------------------------------------------------ */
/* Course */
/* ------------------------------------------------------------ */

.course-week-wrapper {
  margin-bottom: 30px;
}

.course-week-wrapper.active {
  margin-top: 30px;
}

.course-week-header {
  margin-top: 30px;
  margin-bottom: 30px;
}

.course-week-header h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.course-week-header h3 small {
  font-size: 1rem;
  font-weight: normal;
  color: var(--active);
}

.course-week-header p {
  font-style: italic;
  color: var(--gray-tertiary);
  font-size: .9rem;
}




/* ------------------------------------------------------------ */
/* Org Share */
/* ------------------------------------------------------------ */

.org-share-question-container {
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid var(--white-tertiary);
}

.org-share-question-container p:first-of-type {
  margin-bottom: 5px;
  font-weight: 500;
}

.org-share-question-container button {
  margin: 0 15px;
}

@media screen and (max-width: 700px) {

  .org-share-question-container,
  .org-share-question-container button.button {
    font-size: .9rem;
  }

}

@media screen and (max-width: 450px) {

  .org-share-question-container .flex-row {
    flex-direction: column;
  }

  .org-share-question-container button:first-of-type {
    margin-bottom: 30px;
  }

}

@media screen and (min-width: 700px) and (max-width: 800px) {

  .org-share-question-container {
    margin-top: 15px
  }

}


/* ------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------ */


@media screen and (min-width: 700px) {

  .course-week-wrapper .series-lesson-wrapper {
    width: 100%;
    margin-bottom: 30px;
  }

}

@media screen and (min-width: 700px) {

  .main-body-content.lesson-cards.course {
    columns: initial;
  }

  .main-body-content.lesson-cards.course .weekly-goal-wrapper,
  .main-body-content.lesson-cards.course .lesson-card.dashboard-upgrade-card,
  .main-body-content.lesson-cards.course .lesson-card.special-discount-card,
  .main-body-content.lesson-cards.course .community-cta.desktop-only {
    max-width: calc(50% - 30px);
  }

  .main-body-content.lesson-cards.course .lesson-card.dashboard-upgrade-card {
    margin-top: 30px;
  }

  /* .main-body-content.lesson-cards.course .course-week-lessons-wrapper,
  .main-body-content.lesson-cards.course .special-cards-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
  } */

  /* .main-body-content.lesson-cards.course .course-week-lesson-wrapper {
    width: calc(50% - 30px);
  } */

  /* .main-body-content.lesson-cards.course .course-week-wrapper .series-lesson-wrapper,
  .main-body-content.lesson-cards.course .lesson-card {
    margin-bottom: 0px;
  } */

  .main-body-content.lesson-cards.course .community-cta.desktop-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 192px;
  }

  .main-body-content.lesson-cards.course .community-cta.desktop-only h3 {
    font-size: 1.5rem;
  }

}

@media screen and (min-width: 1100px) {

  .main-body-content.lesson-cards.course .weekly-goal-wrapper,
  .main-body-content.lesson-cards.course .lesson-card.dashboard-upgrade-card,
  .main-body-content.lesson-cards.course .lesson-card.special-discount-card,
  .main-body-content.lesson-cards.course .community-cta.desktop-only {
    max-width: calc(33% - 30px);
  }

  .main-body-content.lesson-cards.course .course-week-lesson-wrapper {
    width: calc(33% - 30px);
  }

}

@media screen and (min-width: 1300px) {

  .main-body-content.lesson-cards.course .weekly-goal-wrapper,
  .main-body-content.lesson-cards.course .lesson-card.dashboard-upgrade-card,
  .main-body-content.lesson-cards.course .lesson-card.special-discount-card,
  .main-body-content.lesson-cards.course .community-cta.desktop-only {
    max-width: calc(25% - 30px);
  }

  .main-body-content.lesson-cards.course .course-week-lesson-wrapper {
    width: calc(25% - 30px);
  }

}




.dashboard-aispeaking-card {
  width: 100%;
  padding: 30px;
  margin-top: 30px;
  border: 1px solid var(--white-tertiary);
  border-radius: 10px;
  background-color: var(--gray-light);
}

.dashboard-aispeaking-card .lesson-card-left-bar {
  display: none;
}

.dashboard-aispeaking-card .lesson-card-details-container {
  height: 130px;
}





/* ------------------------------------------------------------ */
/* Start Here / Tour */
/* ------------------------------------------------------------ */

.start-here-wrapper {
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  padding: 10px 0;
  pointer-events: none;
}

.start-here-container {
  width: 100%;
  height: 100%;
  border: 2px solid var(--active);
  border-radius: 10px;
  pointer-events: none;
}

.start-here-arrow {
  position: absolute;
  top: -55px;
  right: 15px;
  width: 200px;
  height: 62px;

  background-image: url('https://files.flowspeak.io/files/take-first-lesson-bubble.png');
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}


@media screen and (min-width: 800px) and (max-width: 1000px) {
  .start-here-arrow {
    right: -70px;
  }
}

@media screen and (min-width: 650px) and (max-width: 799px) {
  .start-here-arrow {
    right: calc(50% - 100px);
  }
}

@media screen and (max-width: 649px) {
  .start-here-arrow {
    top: initial;
    bottom: -55px;
    right: calc(50% - 100px);
    background-image: url('https://files.flowspeak.io/files/take-first-lesson-bubble-up.png');
  }
}






/* ------------------------------------------------------------ */
/* Progress Report */
/* ------------------------------------------------------------ */

.progress-report-container {
  width: 100%;
  max-width: 500px;
  margin-top: 45px;
  padding: 30px;
  border: 1px solid var(--gray-secondary);
  border-radius: 10px;
  background-color: var(--gray-light);
}

.progress-report-container h3 {
  font-family: 'Outfit', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-weight: 600;
}

.progress-report-container h4 {
  margin-top: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.progress-report-level-container {
  width: 100%;
}

.progress-report-level {
  margin-top: 30px;
}

.progress-report-level.disabled {
  opacity: .5;
}

.progress-report-level.disabled .progress-bar-outer {
  background-color: var(--white-tertiary);
  border-color: var(--white-tertiary);
}

.progress-report-level h5 {
  flex-shrink: 0;
  margin: 0 30px 0 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.progress-report-level-inner {
  justify-content: flex-start;
  flex-grow: 1;
  margin-right: 45px;
}

.report-progress-bar-container {
  flex-grow: 1;
}

.progress-report-level .vector-icon {
  display: flex;
}

.report-progress-bar-container .progress-bar-outer {
  background-color: var(--active-extra-light);
}

@media screen and (max-width: 450px)  {

  .progress-report-container {
    padding: 15px;
  }

  .progress-report-level h5 {
    font-size: 1.1rem;
    line-height: 1.1rem;
    margin: 0 15px 0 5px;
  }

  .progress-report-level-inner {
    margin-right: 15px;
  }

}





/* ------------------------------------------------------------ */
/* Promo Container */
/* ------------------------------------------------------------ */

.dashboard-upgrade-promo-container {
  width: fit-content;
  margin-bottom: 15px;
}

.dashboard-upgrade-promo-container.with-profile-setup {
  margin-top: -43px;
  margin-bottom: 15px;
}

.dashboard-upgrade-promo-container a {
  padding: 8px 15px;
  color: var(--gray-primary);
  border-radius: 100px;
  background-color: #FDF1FA;
}

.dashboard-upgrade-promo-container a:hover {
  background-color: #fed1f3;
}


@media screen and (max-width: 600px)  {

  .dashboard-upgrade-promo-container a {
    font-size: .9rem;
  }

}

@media screen and (max-width: 540px)  {

  .dashboard-upgrade-promo-container.with-profile-setup {
    width: 100%;
    margin-top: -5px;
    text-align: right;
  }

  .dashboard-upgrade-promo-container.with-profile-setup a {
    background-color: initial;
  }

}


@media screen and (max-width: 360px)  {

  .dashboard-upgrade-promo-container a {
    padding: initial;
    background-color: initial;
  }

}