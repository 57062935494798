/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700;900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&family=Outfit:wght@100;200;400;500;600;700');

/* font-family: 'Outfit', sans-serif; */



/* ------------------------------------------------------------ */
/* Variables */
/* ------------------------------------------------------------ */

:root {

  --gray-primary: #2D2D2D;
  --gray-secondary: #4A4A4A;
  --gray-tertiary: #6D6D6D;

  --gray-inactive: #8F8F8F;

  --white-primary: #ffffff;
  --white-tertiary: #DCDCDC;

  /* --active: #7808A8;
  --active-half: rgb(120, 8, 168, .5);
  --active-quarter: rgb(120, 8, 168, .25); */

  --active-purple: #7808A8;

  --active: #BC0181;
  --active-half: rgb(188, 1, 129, .5);
  --active-quarter: rgb(188, 1, 129, .25);
  --active-extra-light: rgb(188, 1, 129, .05);
  --active-dark: #860C60;

  --information: #EBF4FF;

  --active-alt: #3F51FF;

  --gray-light: #F5F5F5;
  --gray-extra-light: #FDFDFD;
  /* --gray-background: #FBFBFB; */
  --gray-background: #f9f9f9;

  --error-text: #823976;
  --error-text-alt: #bf1a84;

  --handy-dark: #453042;

  --avatar-border: #4b2144;

  --selected-dark: #5F2951;

  --mobile-outside-padding: 20px;
  --tablet-outside-padding: 30px;
  --desktop-outside-padding: 60px;

  --iphone-bottom-offset: 81px;

  --max-width-app: 1200px;

  --nav-top-height: 168px;

}




/* ------------------------------------------------------------ */
/* Base Layout */
/* ------------------------------------------------------------ */


body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}


body,
button {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--gray-primary);
}

.wrapper {
  min-height: 100vh;
}

.wrapper.special,
.wrapper.gray-background {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper.gray-background {
  background-color: var(--gray-background);
}


.wrapper.invisible {
  opacity: 0;
}

.wrapper.visible {
  transition: opacity 100ms ease-in;
  opacity: 1;
}


nav.nav {
  display: flex; /* max-width */
  flex-direction: column;
  align-items: center;
}

nav.nav .nav-inner-container {
  max-width: var(--max-width-app); /* max-width */
}

.header {
  /* height: 145px;
  min-height: 145px; */
  margin: 30px 0 15px 0;
}

.main-body {
  position: relative;
  flex-grow: 1;
  overflow-y: scroll;
  padding-bottom: 120px;
  width: 100%;
  max-width: var(--max-width-app); /* max-width */
}

.main-body-content {

}

.bottom-nav {
  position: fixed;
  z-index: 3000;
  bottom: 0px;
  width: 100%;

  height: 90px;
  min-height: 90px;
  background-color: var(--white-primary);
}

/* Responsive Layout - take affect at tablet and above */
@media screen and (min-width: 700px) {

  .main-body {
    padding-top: var(--nav-top-height);
  }

  /* .main-body.start {
    padding-top: 60px;
  } */

  /* .bottom-nav {
    display: none !important;
  } */

}

@media screen and (min-width: 801px) {

  .bottom-nav {
    display: none !important;
  }

}







/* ------------------------------------------------------------ */
/* Base styles */
/* ------------------------------------------------------------ */

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--gray-primary);
  font-family: 'Outfit', sans-serif;
}

h1.h1-smaller {
  font-size: 2.125rem;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--gray-primary);
}

h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--gray-secondary);
}

h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--gray-secondary);
}


.flow-logo-lockup,
.org-logo-image {
  width: 130px;
  height: 50px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.flow-logo-lockup {
  background-image: url('https://files.flowspeak.io/files/FLOW-Lockup-on-white.png');
}

.flow-logo-lockup.right-margin {
  margin-right: 60px;
}

/* ------------------------------------------------------------ */
/* Flex Helpers */
/* ------------------------------------------------------------ */

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row.content-left {
  justify-content: flex-start;
}





/* ------------------------------------------------------------ */
/* Stacked Nav */
/* ------------------------------------------------------------ */

.stacked-nav {
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: left;
  background-color: var(--white-primary);
  opacity: .95;
}

.stacked-nav .stacked-nav-header {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  padding-bottom: 0px;
}

.stacked-nav .stacked-nav-primary-links {
  padding: 0 30px;
}

.stacked-nav a,
.stacked-nav .current-link {
  display: block;
  color: var(--gray-primary);
  font-size: 2.25rem;
  padding: 15px;
  font-weight: 500;
}

.stacked-nav a .new-notifications {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-left: 10px;
  margin-bottom: 4px;
  border-radius: 8px;
  background-color: var(--active);
  opacity: .9;
}

.stacked-nav a:hover {
  text-decoration: underline;
}

.stacked-nav .icon-close {
  cursor: pointer;
}

.stacked-nav .stacked-nav-tertiary-links {
  position: absolute;
  bottom: 0px;
  padding: 0 30px 30px;
}

.stacked-nav .stacked-nav-tertiary-links a {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--gray-tertiary);
}


@media screen and (max-height: 760px) {

  .stacked-nav a,
  .stacked-nav .current-link {
    padding: 10px 5px;
    font-size: 2rem;
  }
}

@media screen and (max-height: 660px) {

  .stacked-nav a,
  .stacked-nav .current-link {
    padding: 7px 5px;
    font-size: 1.25rem;
  }

  .stacked-nav .stacked-nav-tertiary-links a {
    font-size: 1.1rem;
  }

}

@media screen and (max-height: 500px) {

  .stacked-nav .stacked-nav-header {
    padding: 15px 30px 0px 30px;
  }

  .stacked-nav .stacked-nav-header button {
    width: 18px;
  }

  .stacked-nav a,
  .stacked-nav .current-link {
    padding: 5px;
    font-size: 1.1rem;
  }

  .stacked-nav .stacked-nav-tertiary-links {
    padding-bottom: 15px;
  }

  .stacked-nav .stacked-nav-tertiary-links a {
    font-size: 1rem;
  }

}


/* ------------------------------------------------------------ */
/* Header Learner Info */
/* ------------------------------------------------------------ */

.header-learner-info-container {
  width: 100%;
}

.header-learner-info-container .header-welcome-text {
  color: var(--gray-tertiary);
}

.header-learner-info-container .header-label {
  margin-bottom: 5px;
  font-size: .8rem;
  line-height: 1rem;
  color: var(--gray-tertiary);
  text-align: left;
}

.header-learner-info-container .header-detail {
  font-size: .9rem;
  color: var(--gray-secondary);
  text-align: left;
}

.header-learner-info-container h2 {
  font-family: 'Outfit', sans-serif;
  line-height: 2.25rem;
  color: var(--gray-secondary);
}

.header-learner-info-container .header-badge-container {
  position: relative;
  min-width: 40px;
  min-height: 40px;
}

.header-learner-info-details-container {
  margin-top: 15px;
}

.header-learner-info-details-left-container div,
.header-learner-info-details-left-container a {
  margin-right: 30px;
}

.header-learner-info-details-left-container button {
  padding: 0px;
}



@media screen and (min-width: 700px) {

  .header-learner-info-container {
    width: auto;
  }

  .header-learner-info-container .header-learner-info-name-avatar,
  .header-learner-info-container .header-learner-info-details-container {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .header-learner-info-container .header-avatar-upload-container {
    width: 32px;
  }

  .header-learner-info-container .header-avatar-upload-container p {
    display: none;
  }

}





/* ------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------ */

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: var(--mobile-outside-padding);
  padding-right: var(--mobile-outside-padding);
}

.header .sub-line {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.header .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header .title-wrapper h1 {
  line-height: 2.75rem;
  /* font-weight: 600; */
  font-weight: 500;
}

.header .title-wrapper p {
  font-size: 1.125rem;
  text-align: center;
}



@media screen and (min-width: 400px) {

  .header {
    padding-left: var(--tablet-outside-padding);
    padding-right: var(--tablet-outside-padding);
  }

}

@media screen and (min-width: 700px) {

  .header {
    align-items: flex-start;
    padding-left: var(--desktop-outside-padding);
    padding-right: var(--desktop-outside-padding);
  }

  .main-body.dashboard .header {
    align-items: center;
  }

}





/* ------------------------------------------------------------ */
/* Main Body */
/* ------------------------------------------------------------ */

.main-body.invisible {
  opacity: 0;
}

.main-body.visible {
  transition: opacity 100ms ease-in;
  opacity: 1;
}

.main-body-content {
  padding-left: var(--mobile-outside-padding);
  padding-right: var(--mobile-outside-padding);
}

@media screen and (min-width: 400px) {

  .main-body-content {
    padding-left: var(--tablet-outside-padding);
    padding-right: var(--tablet-outside-padding);
  }

}

@media screen and (min-width: 700px) {

  .main-body-content {
    padding-left: var(--desktop-outside-padding);
    padding-right: var(--desktop-outside-padding);
  }

}





/* ------------------------------------------------------------ */
/* Lesson Cards - layout */
/* ------------------------------------------------------------ */


.lesson-card {
  width: 150px;
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  width: 100%;
  /* box-shadow: 0 8px 10px -6px rgb(0,0,0, .2); */
  /* box-shadow: 0 6px 8px -3px rgb(0,0,0, .5); */
  box-shadow: 0 6px 15px -4px rgb(0,0,0, .5);
  border-radius: 5px;
}




/* ------------------------------------------------------------ */
/* Weekly goal */
/* ------------------------------------------------------------ */


.weekly-goal-wrapper {
  width: 100%;
  background-color: var(--white-primary);
  border-radius: 10px;
  margin-bottom: 45px;
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 40%);
}


.weekly-goal {
  padding: 15px 30px;
}

.weekly-goal .weekly-goal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.weekly-goal .weekly-goal-header .membership-label {
  font-size: .8rem;
  font-weight: 300;
  padding: 2px 7px;
  border-radius: 5px;
}

.weekly-goal .weekly-goal-header .membership-label span {
  font-weight: 800;
}

.weekly-goal .weekly-goal-header .membership-label.premium {
  color: var(--white-primary);
  background-color: var(--active);
}

.weekly-goal .weekly-goal-header .membership-label.premium strong {
  color: var(--white-primary);
}

.weekly-goal .weekly-goal-header .membership-label.community {
  color: var(--active);
  /* border: 1px solid var(--active); */
}

.weekly-goal .weekly-goal-header .membership-label.community strong {
  color: var(--active);
}

.weekly-goal .weekly-goal-header.course {
  display: inline-block;
}

.weekly-goal .weekly-goal-header.course .membership-label.community {
  padding: 0px 0px 10px 0px;
}

.weekly-goal .weekly-goal-header.course .membership-label.premium {
  display: inline-block;
  margin-bottom: 10px;
  background-color: transparent;
  color: var(--active);
  padding-left: 0px;
}

.weekly-goal .weekly-goal-header.course .membership-label.premium strong {
  color: var(--active);
}

.weekly-goal .title {
  font-size: 1.15rem;
  font-weight: 500;
  margin: 0px;
}

.weekly-goal .goal-description {
  margin-top: 3px;
  color: var(--gray-tertiary);
  font-size: .9rem;
}

.weekly-goal .goal-bar-wrapper,
.weekly-goal .goal-bar {
  height: 10px;
  border-radius: 4px;
  background-color: var(--active-half);
}

.weekly-goal .goal-bar {
  background-color: var(--active);
}

.weekly-goal .goal-bar.empty { width: 0%; }
.weekly-goal .goal-bar.one-sixth { width: 16%; }
.weekly-goal .goal-bar.one-third { width: 33%; }
.weekly-goal .goal-bar.half { width: 50%; }
.weekly-goal .goal-bar.two-thirds { width: 66%; }
.weekly-goal .goal-bar.five-sixths { width: 82%; }
.weekly-goal .goal-bar.full { width: 100%; }





/* ------------------------------------------------------------ */
/* Bottom Nav */
/* ------------------------------------------------------------ */

.bottom-nav {
  display: flex;
  /* align-items: stretch; */
  justify-content: space-between;
}

.bottom-nav .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  color: var(--gray-primary);
  text-align: center;
}

.bottom-nav .nav-link .nav-link-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.bottom-nav .nav-link .nav-link-icon.icon-home {
  width: 32px;
}

.bottom-nav .nav-link .nav-link-text {
  font-size: .9rem;
}

.bottom-nav .nav-link.active .nav-link-text {
  color: var(--active);
}

.bottom-nav .nav-link .nav-link-text:hover {
  cursor: pointer;
}





/* ------------------------------------------------------------ */
/* Icons */
/* ------------------------------------------------------------ */


.vector-icon {
  opacity: .85;
}

.vector-icon:hover {
  opacity: 1;
}

.pointer {
  cursor: pointer;
}

.image.is-24x24 {
  min-width: 24px;
}

.icon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

button.icon {
  border: initial;
  background-color: initial;
}

.play-audio-icon {
  background-image: url('./images/audio-right.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .75;
  cursor: pointer;
}

.play-audio-icon.left {
  background-image: url('./images/audio-left.png');
}

.play-audio-icon:hover {
  opacity: 1;
}

.icon-audio {
  background-image: url('./images/icons/icon-audio-right.png');
  cursor: pointer;
}

.icon-audio:active {
  opacity: .75;
}

.icon-audio.left {
  background-image: url('./images/icons/icon-audio-left.png');
}

.audio-icon {
  opacity: .9;
  cursor: pointer;
}

.audio-icon:hover {
  opacity: 1;
}

.audio-icon:active {
  opacity: .5;
}

.audio-icon.playing {
  animation: audioPlaying 1.25s infinite;
}

@keyframes audioPlaying {
  50% {
    opacity: .5;
  }
}

.audio-icon.left {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}

.icon-audio.dark {
  background-image: url('./images/icons/icon-audio-right-dark.png');
}

.icon-audio.left.dark {
  background-image: url('./images/icons/icon-audio-left-dark.png');
}

.icon-audio-full-active {
  background-image: url('./images/icons/icon-audio-full-active.png');
}

.icon-audio-full-dark {
  background-image: url('./images/icons/icon-audio-full-dark.png');
}

.icon-audio-full-light {
  background-image: url('./images/icons/icon-audio-full-light.png');
}

.icon-audio-full-light-active {
  background-image: url('./images/icons/icon-audio-full-light-active.png');
}

.icon-audio-outline-dark {
  background-image: url('./images/icons/icon-audio-outline-dark.png');
}

.icon-audio-outline-light {
  background-image: url('./images/icons/icon-audio-outline-light.png');
}



.icon-gem {
  background-image: url('./images/icons/icon-gem.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-certificate {
  background-image: url('./images/icons/icon-certificate.png');
}

.icon-certificate-gray {
  background-image: url('./images/icons/icon-certificate-gray.png');
}

.icon-close-light {
  background-image: url('./images/icons/icon-close-light.png');
}

.icon-close {
  background-image: url('./images/icons/icon-close.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-person {
  background-image: url('./images/icon-person.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-retry {
  background-image: url('./images/retry-icon.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-happy {
  background-image: url('./images/icon-happy-face-teal.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-neutral {
  background-image: url('./images/icon-neutral-face-teal.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-unhappy {
  background-image: url('./images/icon-sad-face-teal.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-chevron-right {
  background-image: url('./images/icons/icon-chevron-right.png');
  cursor: pointer;
}

.icon-chevron-right-light {
  background-image: url('./images/icons/icon-chevron-right-light.png');
  cursor: pointer;
}

.icon-chevron-left {
  background-image: url('./images/icons/icon-chevron-left.png');
  cursor: pointer;
}

.icon-arrow-right-light-hallow {
  background-image: url('./images/icons/icon-arrow-right-light-hallow.png');
  cursor: pointer;
}

.icon-arrow-double-up {
  background-image: url('./images/icons/icon-chevron-double-up.png');
  cursor: pointer;
}

.icon-comment {
  background-image: url('./images/icon-comment-bubble.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-flag {
  background-image: url('./images/icon-flag.png');
  cursor: pointer;
}

.icon-this-week {
  background-image: url('./images/icons/icon-this-week.png');
}

.icon-home {
  background-image: url('./images/icons/icons-nav/home-icon-full-dark.png');
}

.icon-live-chat {
  background-image: url('./images/icons/icon-live-chat.png');
}

.icon-saved {
  background-image: url('./images/icons/icon-saved.png');
}

.icon-explore {
  background-image: url('./images/icons/icon-explore.png');
}

.icon-profile {
  background-image: url('./images/icons/icon-profile.png');
}

.icon-progress {
  background-image: url('./images/icons/icon-progress-outline.png');
}

.icon-notifications {
  background-image: url('./images/icons/icon-notifications.png');
}

.icon-notifications.active {
  background-image: url('./images/icons/icon-notifications-active.png');
}

.icon-notifications-light {
  background-image: url('./images/icons/icon-notifications-light.png');
}

.icon-save-heavy {
  background-image: url('./images/icons/icon-save-heavy.png');
}

.icon-save-is-saved {
  background-image: url('./images/icons/icon-save-is-saved.png');
}

.icon-info {
  background-image: url('./images/icons/icon-info.png');
}

.icon-info-light {
  background-image: url('./images/icons/icon-info-light.png');
}

.icon-fire,
.icon-like {
  background-image: url('./images/icons/icon-fire.png');
}

.icon-community {
  background-image: url('./images/icons/icon-community.png');
}

.icon-challenge {
  background-image: url('./images/challenge/icon-challenge-outline-gray.png');
}

.icon-complete {
  background-image: url('./images/icons/icon-complete.png');
}

.icon-complete-purple {
  background-image: url('./images/icons/icon-complete-purple.png');
}

.icon-not-included {
  background-image: url('./images/icons/icon-not-included.png');
}

.icon-danger {
  background-image: url('./images/icons/icon-danger.png');
}

.icon-star {
  background-image: url('./images/icons/icon-star.png');
}

.icon-courses {
  background-image: url('./images/icons/icon-courses.png');
}

.icon-edit {
  background-image: url('./images/icons/icon-edit.png');
}

.icon-stacked-nav {
  background-image: url('./images/icons/icon-stacked-nav.png');
}

.icon-more {
  /* background-image: url('./images/icons/icon-stacked-nav.png'); */
  background-image: url('./images/icons/icons-nav/more-dark.png');
  width: 32px;
}

.icon-lock-dark {
  background-image: url('./images/icons/icon-lock-dark.png');
}

/* .icon-lock-purple {
  background-image: url('./images/icons/icon-lock-purple.png');
} */

.icon-lock-purple {
  background-image: url('./images/icons/icon-lock-burgundy.png');
}





/* ------------------------------------------------------------ */
/* Icon Active States */
/* ------------------------------------------------------------ */

.nav-link.active .icon-this-week {
  background-image: url('./images/icons/icon-pad-active.png');
}

.nav-link.active .icon-home {
  background-image: url('./images/icons/icons-nav/home-icon-full-active.png');
}

.nav-link.active .icon-community {
  background-image: url('./images/icons/icon-community-active.png');
}

.nav-link.active .icon-challenge {
  background-image: url('./images/challenge/icon-challenge-full-burgendy.png');
}

.nav-link.active .icon-progress {
  background-image: url('./images/icons/icon-progress-outline-active.png');
}

.nav-link.active .icon-live-chat {
  background-image: url('./images/icons/icon-live-chat-active.png');
}

.nav-link.active .icon-saved {
  background-image: url('./images/icons/icon-saved-active.png');
}

.nav-link.active .icon-explore {
  background-image: url('./images/icons/icon-explore-active.png');
}

.nav-link.active .icon-courses {
  background-image: url('./images/icons/icon-courses-active.png');
}

.header-icon.active .icon-notifications {
  background-image: url('./images/icons/icon-notifications-active.png');
}

.header-icon.active .icon-profile {
  background-image: url('./images/icons/icon-profile-active.png');
}




/* ------------------------------------------------------------ */
/* Base Styles */
/* ------------------------------------------------------------ */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

span.span-heading {
 text-transform: uppercase;
 font-weight: 600;
 color: gray;
}

.body-content {
  flex-grow: 1;
}

.card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

iframe {
  user-select: none;
}

.special-instruction-text {
  color: var(--mid-dark-green);
  font-weight: 700;
}

.nowrap {
  white-space: nowrap;
}

/* TO DO - clean this up */
.nobr {
  white-space: nowrap;
}

.spacer-15 {
  height: 15px;
}

.spacer-30 {
  height: 30px;
}

.button.no-border {
  border: none;
}

.smaller-text {
  font-size: .9rem;
}






/* ------------------------------------------------------------ */
/* Fade In */
/* ------------------------------------------------------------ */

.fade-wrapper {
  opacity: 0;
}
.fade-wrapper.visible {
  transition: opacity 500ms ease-in;
  opacity: 1;
}

.fade-wrapper.visible-fast {
  transition: opacity 300ms ease-in;
  opacity: 1;
}

.fade-wrapper.invisible {
  transition: opacity 200ms ease-in;
  opacity: 0;
}


.is-flagged {
  display: none;

  /* TO DO - make fun transition */
}

/* .fader {
  transition: opacity 500ms ease-in;
} */


.fade-in-on-load {
  -webkit-animation: fadein 300ms; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 300ms; /* Firefox < 16 */
      -ms-animation: fadein 300ms; /* Internet Explorer */
       -o-animation: fadein 300ms; /* Opera < 12.1 */
          animation: fadein 300ms;
}

.fade-in-on-load.fade-medium {
  -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 500ms; /* Firefox < 16 */
      -ms-animation: fadein 500ms; /* Internet Explorer */
       -o-animation: fadein 500ms; /* Opera < 12.1 */
          animation: fadein 500ms;
}

.fade-in-on-load.fade-slow {
  -webkit-animation: fadein 1000ms; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1000ms; /* Firefox < 16 */
      -ms-animation: fadein 1000ms; /* Internet Explorer */
       -o-animation: fadein 1000ms; /* Opera < 12.1 */
          animation: fadein 1000ms;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}






/* ------------------------------------------------------------ */
/* Toasts */
/* ------------------------------------------------------------ */

.toast {

}

.toast.error {
  padding: 15px;
}

.toast.error p {
  margin-bottom: 15px;
}

.toast.error b {
  /* color: var(--error-text); */
}

.toast-wrapper {
  width: 350px;
  margin-left: -20px;
  margin-right: -20px;
}

.toast-container {
  width: 350px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px;
}

.toast-container p {
  margin-left: 10px;
  margin-top: 3px;
}

.toast-button-container {
  justify-content: flex-end;
  width: 350px;
  margin-top: 30px;
  padding-right: 20px;
}

.clean-button.toast-button {
  width: 80px;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 50px;
  background-color: var(--white-tertiary);
  opacity: .8;
}

.clean-button.toast-button:hover {
  opacity: 1;
}


@media screen and (max-width: 375px) {
  .toast-wrapper,
  .toast-container,
  .toast-button-container {
    width: 300px;
  }
}

@media screen and (max-width: 300px) {
  .toast-wrapper,
  .toast-container,
  .toast-button-container {
    width: 250px;
  }
}





/* ------------------------------------------------------------ */
/* Pathways */
/* ------------------------------------------------------------ */

.pathway-welcome-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}

.pathway-welcome-container h2,
.main-body-content.start .pathway-welcome-container h2 {
  margin-bottom: initial;
  font-size: 1.4rem !important;
  font-weight: 500;
  color: var(--gray-secondary);
  text-align: left;
  font-family: 'Outfit', sans-serif;
}

.pathway-welcome-container p {
  color: var(--gray-secondary);
}

.pathways-container {
  flex-grow: 1;
  min-width: 350px;
  margin-top: 30px;
}

.pathway-select-button {
  width: 100%;
  padding: 0px;
}

.pathway-wrapper {
  padding: 20px 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid var(--gray-light);
  background-color: var(--gray-light);
}

.pathway-wrapper.selected {
  border-color: var(--active);
  background-color: #FDF1FA;
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 15%);
}

.pathway-wrapper h3 {
  margin-bottom: 10px;
  text-align: left;
  font-family: 'Outfit', sans-serif;
}


.pathway-level-block-wrapper {
  align-items: stretch;
}

.pathway-level-block-container {
  position: relative;
  flex-grow: 1;
  width: 20%;
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid var(--gray-primary);
  border-radius: 5px;
  background-color: var(--white-primary);
  opacity: .75;
}

.pathway-level-block-container:first-of-type {
  margin-left: 0px;
}

.pathway-level-block-container:last-of-type {
  margin-right: 0px;
}

.pathway-level-block-container img {
  width: 25px;
  height: 21px;
}

.pathway-level-block-container p {
  margin-top: 2px;
  font-size: .8rem;
}

.pathway-level-block-container p.small {
  font-size: .7rem;
  font-style: italic;
}

.pathway-wrapper.selected .pathway-level-block-container {
  opacity: .45;
}

.pathway-wrapper.selected .pathway-level-block-container.selected {
  opacity: 1;
}

/* .pathway-level-block-container.complete {

} */

.pathway-level-block-container.complete .level-complete-checkmark {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
}

/* .pathway-wrapper.selected .pathway-level-block-container.complete {

} */

.pathway-bottom-container {
  justify-self: flex-end;
  margin-top: 30px;
}

.pathway-learn-link {
  display: block;
  text-align: center;
  font-size: .9rem;
  color: var(--gray-tertiary);
  font-style: italic;
  text-decoration: underline;
}

.reminder-select-wrapper .select-pill,
.reminder-select-wrapper .clean-button.select-pill {
  width: 36px;
  height: 36px;
  padding: 0px;
  text-align: center;
  font-size: .8rem;
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
}

.reminder-select-wrapper .select-pill.selected,
.reminder-select-wrapper .clean-button.select-pill.selected {
  border-color: var(--selected-dark);
}

#root .pathway-wrapper .react-tel-input {
  margin-top: 15px;
  margin-bottom: initial;
}


.pathway-achievement-container {
  width: 100%;
  max-width: 450px;
  padding: 30px;
  text-align: center;
  color: var(--gray-secondary);
  border-radius: 10px;
  border: 1px solid var(--active);
  background-color: var(--gray-background);
}

.pathway-achievement-container h2 {
  color: var(--active);
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  line-height: 2.25rem;
}

.pathway-achievement-container h3 {
  margin-top: 5px;
  color: var(--gray-secondary);
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
}

.pathway-achievement-container h4 {
  color: var(--gray-secondary);
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 1.35rem;
}

.pathway-achievement-container p {
  color: var(--gray-secondary);
  padding: 5px 0;
  line-height: 1.3rem;
}

.pathway-achievement-container img {
  margin: 30px 0;
  width: 120px;
  height: 120px;
}

.pathway-achievement-container .pathway-certificate-container {
  position: relative;
  margin: 30px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--white-primary);
}

.pathway-achievement-container .pathway-certificate-container img {
  margin: 0px;
  width: 235px;
  height: 180px;
}

.pathway-achievement-container .pathway-certificate-container .pathway-certificate-name {
  position: absolute;
  top: 90px;
  left: 0px;
  width: 100%;
  height: 30px;
  text-align: center;
  font-size: 1.2rem;
  text-transform: capitalize;
  font-family: "MonteCarlo", cursive;
}

.pathway-achievement-container button {
  margin-top: 45px;
}

.pathway-achievement-container a {
  margin-top: 15px;
  color: var(--gray-secondary);
  text-decoration: underline;
}


@media screen and (max-width: 410px) {

  .pathways-container {
    min-width: 320px;
    margin-top: 10px;
  }

  .pathway-level-block-container {
    padding: 8px 5px;
  }

  .pathway-level-block-container p {
    font-size: .7rem;
  }

  .pathway-learn-link {
    font-size: .8rem;
  }

}

@media screen and (max-width: 550px) {

  .pathway-level-block-container.complete .level-complete-checkmark {
    top: -5px;
    right: -5px;
  }

}


@media screen and (max-height: 700px) {

  .pathway-wrapper {
    margin: 5px 0;
    padding: 10px;
  }


}


/* ------------------------------------------------------------ */
/* Misc */
/* ------------------------------------------------------------ */

/* .progress-dots-container {
  min-width: 55px;
} */

.progress-dots-container span {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--white-tertiary);
}

.progress-dots-container span.selected {
  background-color: var(--gray-tertiary);
}


.select-pill-container {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.select-pill-container .select-pill {
  margin-bottom: 15px;
  margin-right: 15px;
}

.select-pill,
.clean-button.select-pill {
  background-color: var(--gray-light);
  border-radius: 10px;
  padding: 8px 15px;
}

.select-pill.selected,
.clean-button.select-pill.selected {
  color: var(--white-primary);
  background-color: var(--selected-dark);
}

/* ------------------------------------------------------------ */
/* Misc */
/* ------------------------------------------------------------ */

textarea.shadow-textarea {
  display: block;
  width: 100%;
  padding: 0px;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  color: transparent;
  background-color: transparent;
  border: 0px;
}

.loading-general,
.blank-state-general {
  padding: 90px;
  text-align: center;
  width: 100%;
  color: var(--gray-tertiary);
}

.loading-general .button {
  border: initial;
  background-color: initial;
}

.blank-state-general {

}

.sub-line {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.open-video-guide {
  color: #3273dc;
  cursor: pointer;
}

.open-video-guide:hover {
  color: #363636;
}

.flag-content {
  display: inline-block;
  opacity: .75;
  /* width: 16px;
  height: 16px; */
  color: var(--gray-tertiary);
  font-size: .8rem;
  cursor: pointer;
  margin-top: 2px;
}

.has-pointer {
  cursor: pointer;
}

.active-label {
  color: var(--active);
}

.clean-button {
  border: initial;
  font-family: inherit;
  font-size: initial;
  background-color: transparent;
}

.avatar-image {
  border-radius: 50%;
  /* border: 1px solid var(--avatar-border);
  background-color: var(--active); */
}

.avatar-image-clean {
  border-radius: 50%;
}

.header-avatar-upload-wrapper {
  min-width: 32px;
  height: 32px;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 40px;

  background-color: var(--gray-inactive);
}

.header-avatar-upload-container {
  width: 100px;
  height: 32px;
  padding: initial;
  background-color: var(--white-primary);
}

.header-avatar-upload-container p {
  font-size: .8rem;
}

.header-avatar-upload-icon {
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 1px dashed var(--gray-tertiary);
}

.product-feedback-message {
  width: 100%;
  max-width: 500px;
  min-height: 200px;
  padding: 10px;
}

img.country-flag-image {
  /* width: 30px;
  height: 17px; */
  width: 24px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid var(--white-tertiary);
  background-color: var(--white-primary);
}

.secondary-helper-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.secondary-helper-button {
  font-size: .9rem;
  color: var(--gray-tertiary);
  border: none;
  background-color: var(--white-primary);
  cursor: pointer;
}

.secondary-helper-button:hover {
  color: var(--gray-primary);
}

.secondary-helper-button:active {
  opacity: .5;
}

.terms-text {
  text-align: center;
  color: var(--gray-tertiary);
  font-size: .9rem;
}

.translate-text {
  font-size: .9rem;
  color: var(--gray-tertiary);
}



/* ------------------------------------------------------------ */
/* Social Share */
/* ------------------------------------------------------------ */

.social-share-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-share-container button {
  margin: 0 8px;
}

.social-share-container circle {
  fill: var(--gray-primary);
}

.social-share-container circle:hover,
.social-share-container circle:active {
  fill: var(--gray-tertiary);
}


/* ------------------------------------------------------------ */
/* Audio Processing Component */
/* ------------------------------------------------------------ */

.audio-processing-circle {
  width: 24px;
  height: 24px;
  /* margin: 24px 0 0 24px; */
  margin-left: 15px;
  position: relative;
}

.audio-processing-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.audio-processing-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  /* background-color: #4C4C4C; */
  background-color: var(--white-primary);
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.audio-processing-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.audio-processing-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.audio-processing-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.audio-processing-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.audio-processing-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.audio-processing-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.audio-processing-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.audio-processing-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.audio-processing-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.audio-processing-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.audio-processing-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.audio-processing-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.audio-processing-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.audio-processing-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.audio-processing-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.audio-processing-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.audio-processing-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.audio-processing-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.audio-processing-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.audio-processing-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.audio-processing-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.audio-processing-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}


@media screen and (max-height: 700px) {

  .span-heading {
    font-size: .85rem;
  }

}


.audio-card {
  margin-bottom: 30px;
  padding: 15px;
  border: 1px solid #6D6D6D;
  border-radius: 10px;
}


.response-object {
  font-size: .85rem;
  text-align: left;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
}




/* ------------------------------------------------------------ */
/* Notificaitons - new notification indicator */
/* ------------------------------------------------------------ */

.icon-notifications,
.icon-notifications-light {
  position: relative;
}

.icon-notifications .new-notifications,
.icon-notifications-light .new-notifications {
  position: absolute;
  top: 1px;
  right: 0px;
  width: 13px;
  height: 13px;
  border-radius: 8px;
  background-color: var(--active);
  opacity: .9;
}

@media screen and (max-width: 700px) {

  .icon-notifications .new-notifications,
  .icon-notifications-light .new-notifications {
    background-color: var(--white-primary);
  }

}



/* ------------------------------------------------------------ */
/* Action Button */
/* ------------------------------------------------------------ */

.action-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

a.action-button,
button.button.action-button {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--white-primary);
  padding: 10px 40px;
  /* background: rgb(32,89,255);
  background: linear-gradient(45deg, rgba(32,89,255,1) 0%, rgba(166,52,255,1) 100%); */
  background: var(--active);
  /* background: linear-gradient(45deg, rgba(120,8,168,1) 0%, rgba(181,12,181,1) 100%); */
  /* background: linear-gradient(45deg, rgba(120,8,168,1) 0%, rgba(160,12,176,1) 100%); */
  border-radius: 10px;
  border: none;
}

a.action-button:hover,
button.button.action-button:hover {
  color: var(--white-primary);
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 50%);
}

.action-button-wrapper .back-link {
  color: var(--gray-primary);
  text-decoration: underline;
  padding: 10px 20px;
}




button.button.submit-button-round,
a.button.all-set-button {
  min-width: 200px;
  font-family: 'Outfit', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--white-primary);
  text-decoration: initial;
  border: initial;
  border-radius: 100px;
  background-color: var(--active);
}

button.button.submit-button-round:hover,
a.button.all-set-button:hover {
  color: var(--white-primary);
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 15%);
}




/* ------------------------------------------------------------ */
/* Modal */
/* ------------------------------------------------------------ */

.window-modal-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30001;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.window-modal {
  z-index: 30001;
  width: 75%;
  max-width: 400px;
  min-height: 150px;
  padding: 30px;
  background-color: var(--white-primary);
  border-radius: 5px;
  box-shadow: 0 0px 8px 0px rgb(0,0,0, .5);
}

.window-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.window-modal-header p.modal-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.window-modal-header .icon {
  width: 16px;
  height: 16px;
  margin-bottom: 15px;
  cursor: pointer;
}

.window-modal-subtitle {
  font-weight: 500;
  margin-bottom: 15px;
}

.window-modal-body {

}

.window-modal-body p {
  margin-bottom: 10px;
}

.window-modal-footer {

}

.window-modal-footer.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.window-modal-footer button.basic-button {
  margin-top: 30px;
  padding: 10px 15px;
  border: initial;
  background-color: var(--gray-primary);
  color: var(--white-primary);
  border-radius: 5px;
  font-size: initial;
  cursor: pointer;
}


@media screen and (max-width: 500px) {

  .window-modal {
    width: 85%;
  }

}




/* ------------------------------------------------------------ */
/* Intro tooltips */
/* ------------------------------------------------------------ */

.introjs-tooltip {
  max-width: 325px;
  width: 325px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Move down so the close button aligns with the text */
.introjs-tooltip .introjs-tooltip-header {
  margin-bottom: -45px;
}

.introjs-tooltip .introjs-tooltiptext h3 {
  margin-bottom: 15px;
}

.introjs-tooltip .introjs-tooltiptext p {
  margin-bottom: 10px;
}

.introjs-tooltip .introjs-button {
  border: initial;
  text-shadow: initial;
}

.introjs-tooltip .introjs-button.introjs-nextbutton {
  background-color: var(--active);
  color: var(--white-primary);
  font-weight: 500;
}

.introjs-tooltip .introjs-button:focus {
  box-shadow: initial;
}

.introjs-hint {
  margin: 15px 15px 0 0;
}

.introjs-hint-dot {
  /* border: 10px solid rgba(173, 167, 184, 0.36); */
  border: 10px solid rgba(233, 0, 155, 0.86) !important;
}

.introjs-hint-pulse {
  background-color: rgba(195, 9, 74, 0.24) !important;
  border: 5px solid rgba(238, 0, 234, 0.87) !important;
}



/* ------------------------------------------------------------ */
/* Toggles */
/* ------------------------------------------------------------ */


.action-toggle-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 60px;
  height: 30px;
  padding: 3px;
  border: 1px solid var(--primary-teal);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.action-toggle-wrapper.selected {
  justify-content: flex-end;
}

.action-toggle-wrapper.disabled {
  opacity: .5;
  pointer-events: none;
}

.action-toggle-wrapper .toggle-ball {
  pointer-events: none;
  width: 24px;
  height: 24px;
  border: 5px solid rgba(0, 0, 0, 0.3);
  border-radius: 14px;
  background-color: rgba(0, 0, 0, 0.2);
}

.action-toggle-wrapper.selected .toggle-ball {
  /* border: 5px solid rgba(120, 8, 168, 0.6);
  background-color: rgba(120, 8, 168, 0.4); */
  border: 5px solid rgba(168, 1, 129, 0.6);
  background-color: rgba(172, 0, 118, 0.4);
}



/* ------------------------------------------------------------ */
/* Phone number input */
/* ------------------------------------------------------------ */

#root .react-tel-input {
  margin-bottom: 20px;
}

#root .react-tel-input input {
  font-size: .9rem;
  width: 100%;
  height: 36px;
}

#root .react-tel-input .flag-dropdown {
  /* border: none !important; */
  border: none;
  border-right: 1px solid var(--white-tertiary);
}



/* ------------------------------------------------------------ */
/* Progress Bar */
/* ------------------------------------------------------------ */

.progress-bar-outer {
  height: 10px;
  border: 1px solid var(--active);
  border-radius: 5px;
}

.progress-bar-inner {
  height: 8px;
  background-color: var(--active);
  border-radius: 4px;
}




/* ------------------------------------------------------------ */
/* Progress Steps */
/* ------------------------------------------------------------ */

.progress-steps-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.progress-steps-container .progress-step {
  height: 8px;
  flex-grow: 1;
  border-radius: 4px;
  background-color: var(--active-quarter);
}

.progress-steps-container .progress-step.full {
  background-color: var(--active);
}



/* ------------------------------------------------------------ */
/* Screen Toggler */
/* ------------------------------------------------------------ */

.screen-toggler-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.screen-toggler-container {
  background-color: var(--gray-light);
  border-radius: 30px;
}

.screen-toggler-container button,
.screen-toggler-container .faux-button {
  padding: 10px 30px;
  border-radius: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--gray-primary);
}

.screen-toggler-container button.active,
.screen-toggler-container .faux-button.active {
  color: var(--white-primary);
  background-color: var(--active);
}




/* ------------------------------------------------------------ */
/* Assistant Avatar */
/* ------------------------------------------------------------ */

.avatar-with-audio-image-container {
  position: relative;
  width: 55px;
  min-width: 55px;
  height: 55px;
  min-height: 55px;
}

.avatar-with-audio-image-container img {
  border-radius: 100px;
}

.avatar-with-audio-audio-container {
  position: absolute;
  z-index: 100;
  bottom: 3px;
  right: 3px;
}

.avatar-with-audio-audio-container.learner {
  right: initial;
  left: -3px;
  bottom: 0px;
}

.icon-assistant-generate-audio {
  width: 24px;
  height: 24px;
  padding: initial;
  border: initial;
  border-radius: 100px;
  background-color: var(--active);
  opacity: .65;
}

.button.is-loading.icon-assistant-generate-audio:after {
  /* border-bottom-color: var(--white-primary);
  border-left-color: var(--white-primary); */
  border-bottom-color: #e554b7;
  border-left-color: #e554b7;
}




/* ------------------------------------------------------------ */
/* Record UI Big */
/* ------------------------------------------------------------ */

.big-record-outer-wrapper {
  min-height: 192px;
}

.big-record-wrapper {
  width: 100%;
  margin-top: 60px;
}

.big-record-icon-container-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.big-record-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: var(--active);
  box-shadow: 0 5px 5px -1px rgb(0 0 0 / 20%);
}

.big-record-icon-container:hover {
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 30%);
}

.big-record-container #record-icon {
  width: 50px;
  height: 50px;
  margin-left: initial;
  /* background-image: url('../images/icons/icon-microphone-outline-purple.png'); */
}

.big-record-container .record-response-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.big-record-container .record-response-button p {
  height: 40px;
  width: 120px;
  margin-top: 10px;
  font-size: .9rem;
  font-style: italic;
  color: var(--gray-tertiary);
}


.big-record-container .listening-spinner {
  width: 55px;
  height: 45px;
  margin: 0px;
}

.big-record-container .listening-spinner > div {
  width: 3px;
  background-color: var(--white-primary);
}


.big-record-container .audio-processing-circle {
  width: 30px;
  height: 30px;
  margin-left: initial;
}

.big-record-container .audio-processing-circle .sk-circle:before {
  background-color: var(--white-primary);
}


/* Disabled */
.big-record-wrapper.disabled {
  opacity: .75;
  pointer-events: none;
}

.big-record-wrapper.disabled .big-record-icon-container {
  background-color: var(--gray-inactive);
}





/* ------------------------------------------------------------ */
/* Active buttons */
/* ------------------------------------------------------------ */

a:active,
.vector-icon:active,
.response-score:active {
  opacity: .5;
}




/* ------------------------------------------------------------ */
/* Catalog */
/* ------------------------------------------------------------ */

.main-body-content.catalog {
  min-width: 800px;
}

.catalog-dynamic-learning {
  margin-top: 60px;
}

.catalog-jump-to-section {
  margin-top: 90px;
}

.catalog-jump-to-section a {
  display: block;
  margin-left: 10px;
  padding: 5px 0;
  color: var(--active-dark);
  font-size: 1.25rem;
}

.catalog-jump-to-section a:hover {
  text-decoration: underline;
}

.catalog-lesson-types-container {
  gap: 30px;
  align-items: stretch;
  margin-bottom: 30px;
}

.catalog-lesson-type-container {
  width: 30%;
  border-radius: 10px;
  border: 1px solid #571B70;
}

.catalog-lesson-type-header {
  padding: 15px;
  text-align: center;
  font-weight: 600;
  color: var(--white-primary);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #571B70;
}

.catalog-lesson-type-body {
  padding: 15px;
}


.catalog-lesson-container {
  margin-top: -1px;
  padding: 5px 15px;
  border: 1px solid var(--white-tertiary);
}

.catalog-lesson-container span {
  display: inline-block;
}

.catalog-lesson-container:nth-child(even) {
  background-color: var(--gray-light);
}

.catalog-lesson-container span:first-of-type {
  width: 40px;
  opacity: .65;
}

.catalog-lesson-container span:nth-of-type(2) {
  width: 50%;
  text-align: left;
}

.catalog-lesson-container span:nth-of-type(3) {
  font-size: .9rem;
  text-align: center;
  width: 10%;
}

.catalog-lesson-container span:nth-of-type(4) {
  font-size: .9rem;
  text-align: center;
  width: 15%;
}

.catalog-lesson-container span:nth-of-type(5),
.catalog-lesson-container span:nth-of-type(6),
.catalog-lesson-container span:nth-of-type(7) {
  font-size: .9rem;
  text-align: center;
  width: 60px;
}

.catalog-lesson-container-heading {
  font-weight: 500;
  color: var(--white-primary);
  background-color: #571B70;
}


@media print {

  .main-body-content.catalog {
    min-width: 100%;
  }

  .catalog-lesson-type-header,
  .catalog-lesson-container-heading,
  .unauthed-start-header-logo {
    -webkit-print-color-adjust: exact;
  }

  .catalog-jump-to-section {
    display: none;
  }

  .catalog-dynamic-learning {
    page-break-before: always;
    page-break-after: always;
  }

  .catalog-lesson-container span {
    font-size: .9rem;
  }

  .catalog-lesson-container span:first-of-type {
    font-size: 1rem;
  }

}










/* ------------------------------------------------------------ */
/* Dark Mode */
/* ------------------------------------------------------------ */


@media (prefers-color-scheme: dark) {

  body {
    background-color: #ffffff;
  }

  strong,
  .title,
  .label {
    color: var(--gray-primary);
  }

  select.select,
  input.input {
    color: var(--gray-primary);
    background-color: var(--white-primary);
  }

  /* #root .unauthed-start-header-logo {
    background-image: url('https://files.flowspeak.io/files/dark-mode/flow-logo-lockup-light.png');
  } */
/*
  #root .main-body-content.start .unauthed-start-header-container h1,
  #root .main-body-content.start .unauthed-start-header-container p {
    color: var(--white-primary);
  }

  .image-background.start-image {
    opacity: .5;
  } */

}





/* ------------------------------------------------------------ */
/* My Assessments */
/* ------------------------------------------------------------ */

.my-assessments-container {
  align-items: stretch;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}

.my-assessment-container {
  flex-grow: 1;
  width: 1px;
  min-width: 260px;
  max-width: 350px;
  min-height: 200px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--white-primary);
}

.my-assessment-container p {
  margin-bottom: 0px;
}

.my-assessment-header {
  padding: 30px 30px 15px 30px;
}

.my-assessment-body {
  padding: 30px 30px 20px 30px;
  border-top: 1px solid var(--white-tertiary);
}

.my-assessment-body-inner {
  width: 100%;
  max-width: 300px;
}

.my-assessment-sub-score {
  width: 100%;
  margin-bottom: 10px;
}

.my-assessments-modal {
  max-width: 800px;
  max-height: 80vh;
  padding: initial;
  overflow-y: scroll;
}

.my-assessments-modal .my-assessment-container {
  width: 100%;
  max-width: initial;
}

.my-assessments-modal .window-modal-header {
  padding: 30px 30px 0px 30px;
}

.my-assessment-responses-container {
  padding: 30px;
  border-top: 1px solid var(--white-tertiary);
}



/* Indidual element */

.my-assess-cefr-label {
  color: var(--gray-tertiary);
}

.my-assess-cefr {
  margin-top: 15px;
  font-size: 2rem;
  line-height: 2rem;
  font-family: 'Outfit', sans-serif;
}

.my-assess-small {
  margin-bottom: 0px;
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.my-assessment-response {
  padding: 15px;
  margin-bottom: 15px;
  text-align: left;
  border-radius: 10px;
  background-color: var(--gray-light);
}

.my-assessment-response-title {
  font-size: 1.125rem;
  font-weight: 500;
}

.my-assessment-gray {
  color: var(--gray-tertiary);
}

.assess-score-heading {
  margin-top: 20px;
  font-weight: 500;
}

.assess-score-sub-score {
  padding: 2px 10px;
  border-radius: 40px;
  background-color: #e4e4e4;
}

.assess-score-sub-score small {
  color: var(--gray-tertiary);
}

.my-assessment-start-button {
  padding: 15px 45px;
  font-family: 'Outfit', sans-serif;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: var(--white-primary);
  border-radius: 100px;
  background-color: var(--active);
}

.my-assessment-start-button:hover {
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 15%);
}


.my-assessment-upgrade-message {
  padding-top: 30px;
  border-top: 1px solid var(--white-tertiary);
}

.my-assessment-upgrade-message p {
  margin-bottom: 15px;
}

.my-assessment-upgrade-message a:hover {
  text-decoration: underline;
}


.lesson-card-details-container.dashboard-assessment-cta {
  outline: 1px solid #476CEA;
  background-color: #F3F4FF;
}

.dashboard-assessment-start-link {
  justify-content: flex-start;
  width: fit-content;
  padding: 2px 2px 2px 10px;
  margin-top: 5px;
  color: var(--white-primary);
  border-radius: 50px;
  background-color: #476CEA;
}


@media screen and (max-width: 610px) {

  .my-assessments-container {
    flex-direction: column;
    align-items: center;
  }

  .my-assessment-container {
    width: 100%;
  }

}

@media screen and (max-width: 550px) {

  .my-assessment-responses-container {
    padding: 15px;
  }

}