.lesson-card-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.lesson-card-details-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  background-color: var(--white-primary);
  margin: 10px 0;
  color: var(--gray-primary);
}

.lesson-card-details-container:hover {
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 5%);
}

.lesson-card-image {
  width: 30%;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.lesson-card-content-container {
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.lesson-card-content {
  display: block;
  width: 100%;
  padding: 15px;
  color: var(--gray-primary);
}

.lesson-card-content h6 {
  margin: 8px 0 3px 0;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

.lesson-card-content p {
  font-size: .9rem;
}

.lesson-card-content p.small {
  font-size: .8rem;
}

.lesson-card-content p.light-gray {
  color: var(--gray-tertiary);
}

.lesson-card-content p.repeat-lesson {
  font-size: .8rem;
}

.lesson-card-type-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lesson-card-type-container .icon {
  width: 15px;
  height: 15px;
  /* margin-right: 10px; */
}

.lesson-card-type-container p.display-lesson-type {
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.lesson-card-content-container .completed_on {
  margin-top: 5px;
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.lesson-card-action-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.lesson-card-action-container.with-border {
  min-height: 33px;
  padding-top: 5px;
  /* border-top: 1px solid var(--white-tertiary); */
  border-top: 1px solid #ededed;
}

.lesson-card-action-container.with-button {
  margin-top: -5px;
}

.lesson-card-action-container.with-button .start-button-minimal {
  margin-bottom: 5px;
}

.lesson-card-action-container a {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

/* a.start-button-minimal {
  align-items: center;
  padding: 3px 15px;
  font-size: .8rem;
  color: var(--gray-secondary);
  border-radius: 50px;
  border: 1px solid var(--gray-secondary);
}

.start-button-minimal .vector-icon,
.lesson-card-retake-lesson .vector-icon {
  height: 15px;
  width: 10px;
}

.start-button-minimal:hover {
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 15%);
} */

.lesson-card-retake-lesson .vector-icon {
  height: 15px;
  width: 10px;
}

.lesson-card-action-container .pie.animate {
  --c: var(--gray-tertiary) !important;
}


/* ------------------------------------ */
/* Status Indicator */
/* ------------------------------------ */

.lesson-card-left-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-height: 150px;
}

.status-top-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-bottom: 10; */
}

.status-bottom-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 10; */
}

.status-left,
.status-right {
  flex-grow: 1;
}

.status-center {
  width: 1px;
}

.status-center-line {
  background-color: var(--white-tertiary);
}

.status-indicator {
  line-height: 1px;
  margin: 10px 0;
}

.status-indicator .icon {
  width: 1.125rem;
  height: 1.125rem;
}

.icon-circle-gray {
  border-radius: 50%;
  background-color: #a1a1a1;
}

.icon-lock-dark {
  opacity: .5;
}




/* ------------------------------------ */
/* Repeat Element */
/* ------------------------------------ */

.repeat-element-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: 3px;
  font-size: .9rem;
  color: var(--gray-tertiary);
  cursor: pointer;
}

.repeat-element-container .icon {
  width: .9rem;
  height: .9rem;
  margin-left: 5px;
}


/* ------------------------------------ */
/* Responsive */
/* ------------------------------------ */

@media screen and (min-width: 800px) {

  .lesson-card-wrapper {
    width: 48%;
  }

  .status-center-line {
    background-color: transparent;
  }

}





/* ------------------------------------ */
/* Big Card */
/* ------------------------------------ */

.lesson-card-wrapper.lesson-card-big {
  width: 100%;
}

.lesson-card-wrapper.lesson-card-big .lesson-card-details-container {
  min-height: 175px;
}

.lesson-card-wrapper.lesson-card-big .lesson-card-image {
  width: 175px;
  min-width: 175px;
}




/* ------------------------------------ */
/* Big Card */
/* ------------------------------------ */

.lesson-card-content-footer {
  margin: 12px -15px -15px -15px;

  padding: 8px 15px;
  width: calc(100% + 30px);
  justify-content: space-between;
  border-top: 1px solid var(--white-tertiary);
}

.lesson-card-content-footer a {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.lesson-card-content-footer a .vector-icon {
  height: 1rem;
}

.lesson-card-content-footer .pie.animate {
  --c: var(--gray-tertiary) !important;
}

.lesson-card-content-footer a:hover {
  color: var(--gray-tertiary);
  text-decoration: underline;
}



/* ------------------------------------ */
/* Small Card */
/* ------------------------------------ */

.lesson-card-small-wrapper {
  padding: 5px;
  flex: 1 1 0px;
}

.lesson-card-small-container {
  display: block;
  height: 100%;
  min-width: 140px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--white-tertiary);
  background-color: var(--white-primary);
  text-align: left;
}

.lesson-card-small-container:hover {
  border-color: #dadada;
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 5%);
}

.lesson-card-small-container h4 {
  margin: 5px 0;
  font-family: 'Outfit', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.lesson-card-small-container p {
  font-size: .9rem;
  margin: 0px 0;
}