/* ------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------ */

.assess-wrapper {
  min-height: 100vh;
}

.assess-background {
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-image: url('https://files.flowspeak.io/files/assessment-gradient.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: clamp(1200px, 100%, 2000px);
}

.assess-continer {
  z-index: 2;
  width: 100%;
  padding: 45px 30px;
  max-width: 500px;
  min-height: 100vh;
  position: relative;
}

.assess-glass,
.assess-glass-extra {
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255, .9)
}

/* .assess-glass {
  position: relative;
} */

.assess-glass-extra {
  background-color: rgb(255, 255, 255, .68)
}

p.assess-h6 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  font-weight: 400;
}

.assess-continer h1 {
  color: var(--gray-secondary);
}

.assess-continer h2 {
  margin-bottom: 10px;
  color: var(--gray-secondary);
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.assess-continer input {
  margin: 8px 0;
}

.assess-instructions-container {
  margin-top: 15px;
}

.assess-instructions-container p {
  margin-bottom: 15px;
}

.assess-translate-container {
  width: calc(100% + 60px);
  margin: 15px 0 30px 0;
  padding: 15px 30px;
  background-color: rgb(255, 255, 255, .5)
}

.assess-translate-container p {
  margin-bottom: 10px;
}

.assess-instructions-container p.p-list-item,
.assess-translate-container p.p-list-item {
  display: list-item;
  margin-bottom: 10px;
  margin-left: 15px;
}

p.translation-p {
  margin-top: 5px;
}

p.translation-p,
.assess-translate-container {
  color: var(--gray-tertiary);
  font-style: italic;
  font-size: .9rem;
}

p.assess-fancy {
  font-family: 'Outfit', sans-serif;
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 300;
  color: var(--gray-secondary);
  text-align: center;
}

p.assess-fancy.small {
  font-size: 1.125rem;
  line-height: 1.3rem;
  font-weight: 400;
}

p.access-error-message {
  color: var(--error-text-alt);
  text-align: center;
}

p.assess-agree {
  margin: 30px 0;
  font-size: .9rem;
  line-height: 1.2rem;
  text-align: center;
  color: var(--gray-tertiary);
}

.assess-continer .button.submit-button-round:disabled {
  background-color: var(--gray-secondary);
}

.assess-instruction-heading {
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 15px;
  padding: 0px 30px;
  border-bottom: 1px solid #B2ACC3;
}


.testing-element {
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 30px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, .75);
  border: 1px solid var(--white-primary);
  box-shadow: 0 6px 15px -4px rgb(0,0,0, .25);
}





/* ------------------------------------------------------------ */
/* Record */
/* ------------------------------------------------------------ */

.assess-record-container {
  flex-grow: 1;
  min-height: 40vh;
  margin-top: 30px;
}

.assess-review-container {
  margin-top: 30px;
  margin-bottom: 90px;
  padding: 30px;
  border-radius: 10px;
  background-color: #ECE9F3;
}

.assess-review-audio-container {
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 15px;
  border-radius: 100px;
  background-color: var(--gray-secondary);
}

button.assess-retry-button {
  font-family: 'Outfit', sans-serif;
  color: var(--white-primary);
  border: none;
  background-color: var(--gray-tertiary);
}

button.assess-retry-button:hover {
  color: var(--white-primary);
  background-color: var(--gray-secondary);
}

.asset-review-timer-text {
  margin-top: 15px;
  font-size: .8rem;
  font-style: italic;
  color: var(--gray-secondary);
}






/* ------------------------------------------------------------ */
/* Start */
/* ------------------------------------------------------------ */

.assess-start-logos {
  justify-content: center;
}

.assess-start-words {
  margin-top: 45px;
  margin-bottom: 60px;
}

.assess-start-words.big {
  margin-top: 90px;
  margin-bottom: 90px;
}


.assess-sub-heading {
  color: var(--gray-secondary);
  font-size: 1.1rem;
  font-weight: 400;
  font-family: 'Outfit', sans-serif;
}

.assess-heading {
  margin-top: -10px;
  color: var(--gray-secondary);
  font-size: 2.25rem;
  font-weight: 600;
  font-family: 'Outfit', sans-serif;
}

.assess-start-words.big .assess-sub-heading {
  font-size: 1.3rem;
}

.assess-start-words.big .assess-heading {
  font-size: 2.75rem;
}

.assess-dots-container {
  margin-top: 20px;
}

.assess-audio-button-container {
  margin-top: 60px;
}

button.icon.assess-audio-button {
  width: 65px;
  height: 65px;
  padding: 50px;
  background-size: 65px;
  border-radius: 100px;
  background-color: var(--gray-secondary);
}

.assess-audio-buttons-container {
  justify-content: center;
  gap: 30px;
  min-height: 40px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
}

.assess-audio-buttons-container button {
  width: 75px;
  border-color: var(--gray-secondary);
}

.assess-audio-buttons-container button:first-of-type {
  color: var(--error-text-alt);
  border-color: var(--error-text-alt);
}

.assess-mic-test-container {
  width: 100%;
  text-align: center;
}

.assess-mic-test-container .record-response-button p {
  display: none;
}

/* .assess-mic-test-container .big-record-icon-container { */
.assess-continer .big-record-icon-container {
  background-color: var(--gray-secondary);
}

.assess-mic-extra-text {
  width: 100%;
  min-height: 50px;
}

.assess-input-code {
  text-align: center;
}

.assess-code-form {
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.assess-continer .select {
  padding-left: 10px;
  padding-inline-end: 10px;
}


.assess-continer select.select option:disabled {
  color: var(--gray-tertiary);
}




/* ------------------------------------------------------------ */
/* Read */
/* ------------------------------------------------------------ */

.assess-passage-number-container {
  font-size: .9rem;
  color: var(--gray-tertiary);
  margin-bottom: 30px;
}

.assess-passage-container {
  width: calc(100% + 60px);
  min-height: 200px;
  padding: 0 30px 30px;

  border-bottom: 1px solid #B2ACC3;
}

.assess-timer-container {
  position: absolute;
  z-index: 1;
  /* bottom: 10px;
  left: 10px; */
  bottom: 60px;
  left: 45px;
  width: 70px;
  padding: 10px;
  color: var(--white-primary);
  border-radius: 5px;
  background-color: #64616D;
}

.assess-timer-container p:nth-child(1) {
  font-size: .9rem;
}

.assess-timer-container p:nth-child(2) {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
}

.assess-timer-container p:nth-child(3) {
  font-size: .8rem;
}






/* ------------------------------------------------------------ */
/* Conversation */
/* ------------------------------------------------------------ */

.assess-continer .avatar-with-audio-audio-container.learner {
  display: none;
}

.assess-glass.conversation-thread {
  height: calc(100% - 60px);
  overflow-y: scroll;
}

.asess-conversation-hint {
  margin-left: 85px;
  margin-right: 45px;
  font-style: italic;
  font-size: .9rem;
  color: var(--gray-secondary);
}


@media screen and (min-width: 1100px) {

  .assess-continer .aitalk-message-container.learner {
    margin-left: 30px;
  }

  .assess-continer .aitalk-message-container.assistant {
    margin-right: 30px;
  }

}




/* ------------------------------------------------------------ */
/* Score */
/* ------------------------------------------------------------ */

.assess-score-waiting-header {
  margin-bottom: 45px;
}

.assess-score-waiting-header h2 {
  font-family: 'Outfit', sans-serif;
}

.assess-score-section {
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 45px 30px;
  border-bottom: 1px solid #B2ACC3;
}

.assess-score-section:last-of-type {
  border-bottom: initial;
}

.assess-cefr-level {
  margin-top: 30px;
  color: var(--active);
  font-family: 'Outfit', sans-serif;
  font-size: 3.75rem;
  line-height: 4rem;
  font-weight: 600;
}

.assess-cefr-level-detail {
  font-size: .9rem;
  font-style: italic;
  color: var(--gray-tertiary);
}


/* Sub score */

.assess-sub-container,
.assess-sub-indicator-container {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  font-size: .7rem;
}

.assess-sub-level {
  position: absolute;
  right: 0px;
  align-items: flex-start;
  justify-content: center;
  height: 40px;
  min-width: 10%;
  color: var(--gray-secondary);
  border-radius: 100px;
  border: 1px solid #658699;
  background-color: #ECF8FF;
}

.assess-sub-level.is_b {
  background-color: #D3EEFD;
}

.assess-sub-level.is_c {
  background-color: #AAE0FF;
}

.assess-sub-level-inner {
  width: 37px;
}

.assess-sub-indicator-container .assess-sub-level {
  font-size: 1.5rem;
  border: initial;
  background-color: initial;
}

.assess-up-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #405966;
}

.assess-test-taker-details {
  margin: 45px 0;
}

.assess-test-taker-name {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
}


/* Bars */
.cefr-sub-bar-wrapper {
  width: 100%;
  margin-top: 15px;
}

.cefr-sub-bar-wrapper h4 {
  font-size: 1.1rem;
  font-weight: 400;
}

.cefr-sub-bar-container {
}

.cefr-sub-bar-container p {
  font-size: 1.125rem;
}

.cefr-sub-bar-outer {
  flex-grow: 1;
  height: 12px;
  margin-right: 15px;
  border-radius: 10px;
  border: 1px solid var(--active);
  background-color: var(--active-extra-light);

  /* box-shadow: inset 0 -2px 3px var(--active-quarter); */
}

.cefr-sub-bar-inner {
  height: 100%;
  border-radius: 10px;
  background-color: var(--active);
}


/* Responses */
.assess-response-wrapper {
  width: 100%;
  margin-bottom: 30px;
}

p.assess-score-p {
  font-size: .95rem;
  line-height: 1.4rem;
}

.assess-response-wrapper h4 {
  font-family: 'Outfit', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}

.assess-response-button {
  width: 100%;
  cursor: pointer;
}

.assess-response-button:active {
  opacity: .5;
}

.assess-response-container {
  border-radius: 10px;
  background-color: #f3f3f3;
  display: none;
}

.assess-response-header {
  margin-bottom: 15px;
  padding: 15px;
  border-bottom: 1px solid var(--white-tertiary);
}

.assess-response-header p.assess-score-p {
  width: calc(100% - 65px);
}

.assess-response-scores {
  padding: 0 15px 15px 15px;
}

.assess-response-scores-row {
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.assess-response-scores .assess-response-scores-row-donut {
  margin: 15px 0px;
}

.assess-response-scores p.assess-score-response-p {
  font-family: 'Outfit', sans-serif;
  font-size: .8rem;
  font-weight: 400;
  color: var(--gray-tertiary);
}

.assess-response-scores .score-donut-wrapper {
  margin-left: initial;
}


.assess-response-scores .score-donut-wrapper.with-text .score-donut,
.assess-response-scores .score-donut-wrapper.with-text .donut-hole {
  width: 50px;
  height: 50px;
}


.assess-response-scores .score-donut-wrapper.with-text .pie {
  --b: 6px;
  --w: 50px;
}

.assess-response-scores .score-donut-wrapper.with-text .donut-hole {
  border-width: 6px;
}

.assess-response-scores .score-donut-wrapper.with-text .donut-hole p {
  margin-top: 2px;
}

.assess-response-feedback {
  padding: 30px 15px;
  border-top: 1px solid var(--white-tertiary);
}

.assess-response-feedback div {
  margin-bottom: 15px;
}

.assess-response-feedback div:last-of-type {
  margin-bottom: initial;
}




/* ------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------ */

@media screen and (max-width: 400px) {

  .assess-sub-container,
  .assess-sub-indicator-container,
  .assess-sub-level {
    height: 30px;
  }

  .assess-sub-level-inner {
    width: 29px;
  }

}

@media screen and (max-width: 440px) {

  .assess-sub-container,
  .assess-sub-indicator-container,
  .assess-sub-level {
    height: 35px;
  }

  .assess-sub-level-inner {
    width: 33px;
  }

}



@media screen and (max-width: 485px) {

  .assess-continer {
    padding: 30px 15px;
  }

  .assess-instruction-heading {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
  }

  .assess-passage-container {
    width: calc(100% + 40px);
    padding: 0 20px 20px;
  }

  .assess-translate-container {
    width: calc(100% + 40px);
    padding: 20px;
  }

  .assess-glass,
  .assess-glass-extra {
    padding: 20px;
  }

  .assess-continer .aitalk-message-container.assistant {
    margin-right: 0px;
    font-size: .9rem;
  }

  .assess-continer .aitalk-message-container.learner {
    margin-left: 0px;
    font-size: .9rem;
  }

  .assess-score-section {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .assess-timer-container {
    bottom: 30px;
    left: 30px;
  }

}


@media screen and (max-height: 700px) {

  .assess-continer h1 {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .assess-start-words,
  .assess-start-words.big {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .assess-continer h2 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .assess-instructions-container p {
    /* margin-bottom: 10px; */
    font-size: .9rem;
    line-height: 1.3rem;
  }

  .assess-instructions-container p.p-list-item,
  .assess-translate-container p.p-list-item {
    margin-bottom: 3px;
    /* margin-left: 10px; */
  }

  p.assess-fancy {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  p.assess-fancy.small {
    font-size: 1rem;
    line-height: 1.1rem;
  }

  .assess-audio-button-container {
    margin-top: 30px;
  }

  button.icon.assess-audio-button {
    width: 45px;
    height: 45px;
    padding: 40px;
    background-size: 45px;
  }

  .big-record-wrapper {
    margin-top: 15px;
  }

  .assess-passage-number-container {
    margin-bottom: 15px;
  }

  .assess-review-container {
    margin-top: 20px;
    margin-bottom: 40px;
  }

}


@media screen and (min-height: 701px) and (max-height: 800px) {

  .assess-continer h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .assess-instructions-container p {
    margin-bottom: 10px;
  }

  .assess-start-words,
  .assess-start-words.big {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .assess-audio-button-container {
    margin-top: 30px;
  }

  button.icon.assess-audio-button {
    width: 55px;
    height: 55px;
    padding: 45px;
    background-size: 55px;
  }

  .big-record-wrapper {
    margin-top: 30px;
  }

  .assess-review-container {
    margin-top: 30px;
    margin-bottom: 45px;
  }

}